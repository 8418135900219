import React from "react";
import { Link } from "react-router-dom";

const HomePage = (props) => {
  return (
    <div className="App-header">
      <h1>BOP DOT</h1>
      <h2>Game Modes</h2>
      <div className="linkContainerDiv">
        <Link to={"/CoOp"}>
          <div className="modeButton">Co-Op</div>
        </Link>
        <Link to={"/Battle"}>
          <div className="modeButton">Battle</div>
        </Link>
        <Link to={"/PatternSolo"}>
          <div className="modeButton">Pattern</div>
        </Link>
      </div>
    </div>
  );
};

export default HomePage;
